



















import { Vue, Prop, Component } from 'vue-property-decorator';
import Botton from '@/components/base/pc/InsButton.vue';
import InsInput from '@/components/base/pc/InsInput.vue';
import InsForm from '@/components/base/pc/InsForm.vue';
@Component({ components: { Botton, InsInput, InsForm } })
export default class InsPassword extends Vue {
    private password = {
      Password: '',
      NewPassword: '',
      ConfirmNewPassword: ''
    }
    submit () {
      (this.$refs.form as InsForm).validate((e) => {
        if (!e) return;
        this.$Api.member.updatePassword(this.password).then(
          (response) => {
            if (response.Succeeded) {
              this.$message({
                message: response.Message,
                type: 'success'
              });
              this.$emit('updatePassword');
            } else {
              this.$message({
                message: response.Message,
                type: 'error'
              });
              (this.$refs.form as InsForm).reset();
            }
            // _this.$router.push('/account/registerSuccess');
          },
          (response) => {
            console.log(response.Message, '3333333333333');
            this.$message({
              message: response.Message,
              type: 'error'
            });
          }
        );
      });
    }
}
