



































import { Vue, Prop, Component } from 'vue-property-decorator';
import { MemberResult } from '@/model/memberResult';
import Botton from '@/components/base/pc/InsButton.vue';
import InsSelect from '@/components/base/pc/InsSelect.vue';
import InsInput from '@/components/base/pc/InsInput.vue';
import InsForm from '@/components/base/pc/InsForm.vue';
@Component({ components: { Botton, InsSelect, InsInput, InsForm } })
export default class InsProfileMain extends Vue {
  private member: MemberResult = new MemberResult();
  private lang:any[] = [];
  langs:string[] = ['E', 'C', 'S'];
  created () {
    this.lang = [{ 'Id': 0, 'Name': this.$t('Register.UserLanguageE') }, { 'Id': 1, 'Name': this.$t('Register.UserLanguageT') }];
    this.$Api.member.getProfile().then((result) => {
      this.member = result || new MemberResult();
      this.$store.dispatch('setUser', (result.FirstName + ' ' + result.LastName).toUpperCase());
      this.$i18n.locale = this.langs[result.Language];
      this.$store.dispatch('setLang', this.langs[result.Language]);
      this.$Storage.set('locale', this.langs[result.Language]);
      this.$HiddenLayer();
    });
  }
  mounted () {
  }
  submit () {
    (this.$refs.form as InsForm).validate((e) => {
      if (!e) return;
      this.$Api.member.updateProfile(this.member).then(
        (response) => {
          this.$i18n.locale = this.langs[this.member.Language];
          // this.$Storage.set('locale', this.langs[this.member.Language]);
          this.$Api.member
            .setUILanguage(this.member.Language)
            .then(result => {
              this.$i18n.locale = this.langs[this.member.Language];
              this.$Storage.set('locale', this.langs[this.member.Language]);
            })
            .catch(error => {
              console.log(error);
            });
          this.Reload();
        },
        (response) => {
          this.$message({
            message: response.Message,
            type: 'error'
          });
        }
      );
    });
  }
}
