
























import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import ProductAttr from '@/model/ProductAttr';
@Component
export default class InsSelect extends Vue {
  // data
  private Value: string = '';
  private RealValue: string = '';
  private None: boolean = false;
  private Content: string[] = [];
  private DropdownShow: boolean = false;
  private Placeholder = '请选择';
  //   props
  @Prop() private readonly label!: string;
  @Prop() private readonly styla!: string;
  @Prop() private readonly items!: ProductAttr[];
  @Prop() private readonly value!: any;
  //   method
  choice (e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (target.nodeName !== 'LI') {
      return;
    }
    this.Value = this.items[target.dataset.key as string].Name;
    this.RealValue = this.items[target.dataset.key as string].Id;
    this.DropdownShow = false;
    this.$emit('input', this.RealValue);
  }
  leftDropdown (e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (target.nodeName === 'DIV') {
      this.DropdownShow = false;
    }
  }
  //   created
  created () {
    if (this.value) {
      this.Value = this.items[this.value].Name;
    } else if (this.items.length > 0 && !this.value) {
      this.Value = this.items[0].Name;
      this.RealValue = this.items[0].Id;
      this.$emit('input', this.RealValue);
    }
  }
  @Watch('items', { deep: true })
  onItemsChange () {
    if (this.items.length > 0) {
      this.Value = this.items[0].Name;
      this.RealValue = this.items[0].Id;
      this.$emit('input', this.RealValue);
    }
  }
  @Watch('value')
  onvalueChange () {
    this.Value = this.items[this.value].Name;
    this.RealValue = this.items[this.value].Id;
  }
}
